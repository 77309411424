<template>
  <div class="news-detail">
    <div class="nav-title">
      <i></i>首页— 
      {{titleObj[type]}}
    </div>
    <div class="content">
      <div class="title">
        <div>
          <span class="active">{{titleObj[type]}}</span>
        </div>

        <div>
          <span class="button-primary" @click="onBack">返回</span>
        </div>
      </div>
      <div class="list">
        <div class="tips-title">{{ announceDetailData.title }}</div>
        <div class="tips-date">{{ announceDetailData.sysPublishTime }}</div>
        <div class="ql-editor" v-html="announceDetailData.content" />
      </div>
    </div>
  </div>
</template>

<script>
import { getAnnounceInfo, aboutUs } from '@/api/announce.js'

export default {
  components: { },
  data() {
    return {
      titleObj: {
        'announce': '公告栏',
        'about': '关于我们'
      },
      type: 'announce',
      announceDetailData: {}
    }
  },
  watch: {
    $route() {
      this.$router.go(0)
    },
  },
  mounted() {
    this.type = this.$route.query.type
    this.id = this.$route.query.id || ''
    if (this.type == 'announce') {
      this.onSee()
    } else if (this.type == 'about') {
      this.onAbout()
    }
  },
  methods: {
    // 查看公告详情
    async onSee() {
      const res = await getAnnounceInfo({
        id: this.id
      })

      this.announceDetailData = res.data
    },
    // 关于我们
    async onAbout() {
      const res = await aboutUs()

      this.announceDetailData = res.data
    },
    onBack() {
      this.$router.go(-1)
    }
  }
};
</script>

<style lang="less">
@import "../../styles/quill.bubble.css";
@import "../../styles/quill.core.css";
@import "../../styles/quill.snow.css";

.news-detail {
  width: 100%;
  background: #edf0f2;
  padding: 46px 0px 100px;
  box-sizing: border-box;

  .content {
    width: auto;
    padding: 0px 51px;

    .title {
      display: flex;
      justify-content: space-between;
      & > div {
        display: flex;
      }
      .button-primary {
        height: 34px !important;
        margin-right: 0;
        color: #6868CE;
      }
    }

    .list {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      border: 1px solid #e6e6e6;
      margin-top: 10px;
      padding: 0px 26px 16px 26px;
      box-sizing: border-box;
    }

    .tips-title {
      font-size: 16px;
      font-weight: 500;
      color: #4A4A4A;
      margin: 29px 0 4px;
    }

    .tips-date {
      font-size: 12px;
      font-weight: 400;
      color: #4A4A4A;
      padding-bottom: 20px;
      border-bottom: 1px dashed #C6C4C4;
    }
  }
}
</style>
